import React, { useState } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/layout';

import { Link } from 'gatsby';

import SmartFeedFiltersComponent from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedWithFilters_WT';

export default function HomepagePage_Content(props) {
  const {
    pageContext: { config },
  } = props;

  const siteConfig = config;

  const [vacancyLoading, setVacancyLoading] = useState(true);

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      title={`${
        siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName || ''
      } Careers`}
      description={`Careers at ${
        siteConfig.CompanyName || props.smartfeedCompanyData.CompanyName
      }`}
    >
      <div className="wrapper wrapper--not-mobile">
        <section
          className="page-hero vacs-homepage-hero"
          style={{
            backgroundImage: `url(https://cdn1.accropress.com/OaklandCare/VacancyPages/OakLandCareHome.jpg)`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="u-opacity-layer">
            <div className="image-parent">
              <img
                className="top-employer-banner"
                src="https://oaklandcare.com/app/themes/oaklandcare/dist/images/oakland-care-top-employer-2021_8ae092a5.gif"
                alt="Oakland Care logo"
              />
            </div>{' '}
          </div>
          <div className="page-hero__inner">
            <div className="page-hero__content">
              <h1>Join our team at Oakland Care</h1>
            </div>
          </div>
        </section>
      </div>

      <div className="wrapper hidden">
        <section className="two-column-intro-block">
          <div className="columns is-variable is-8 fadein fadein-active">
            <div className="column is-6 ">
              <h2>
                <strong>Recruiting Now. </strong> Join our team of dedicated
                care professionals at Oakland Care.
              </h2>
            </div>

            <div className="column is-6">
              <div className="rte">
                <div className="rich-text">
                  <p>
                    <strong>
                      With the outbreak of Covid-19 affecting jobs across the
                      UK, Oakland Care want to help you secure full-time,
                      part-time or flexible employment.{' '}
                    </strong>
                  </p>
                  <p>
                    We are currently recruiting Nurses, Carers, Hospitality,
                    Housekeeping and Front of House staff.{' '}
                  </p>
                  <p>
                    <strong> We're offering: </strong>
                  </p>
                  <ul>
                    <li>Quick start dates</li>
                    <li>Flexible hours to suit you </li>
                    <li>No minimum hours</li>
                    <li>Competitive pay rates</li>
                  </ul>
                  <p>
                    <strong>
                      We are looking for caring and enthusiastic individuals -
                      no matter your background or circumstances, come and make
                      a difference to people’s lives today.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      {' '}
                      <a href="https://www.surveymonkey.co.uk/r/GVYFR6N">
                        Register your interest.
                      </a>{' '}
                    </strong>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SmartFeedFiltersComponent
        siteConfig={siteConfig}
        setVacancyLoading={setVacancyLoading}
        vacancyLoading={vacancyLoading}
        apiKey={siteConfig.apiKey || props.apiKey}
        group={
          siteConfig.group
            ? siteConfig.group
            : props.groupOrIdParam === 'id'
            ? false
            : props.groupOrIdParam === 'group'
            ? true
            : false
        }
        gdprKey={siteConfig.gdprKey || siteConfig.apiKey || props.apiKey}
        smartfeedCompanyData={
          siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
        }
        CompanyGroupName={
          siteConfig.companyName || props.smartfeedCompanyData.CompanyName
        }
        OptInLink={
          siteConfig.companyCareersSite
            ? `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=`
            : siteConfig.OptInLink ||
              `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/optin/?OptInID=`
        }
        UnsubscribeLink={
          siteConfig.companyCareersSite
            ? `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=`
            : siteConfig.UnsubscribeLink ||
              `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/unsubscribe/?UnsubscribeID=`
        }
        VacancyPageBaseURL={
          siteConfig.companyCareersSite
            ? `${siteConfig.careersSiteDomain}/vacancies/vacancy/`
            : siteConfig.VacancyPageBaseURL ||
              `${siteConfig.careersSiteDomain}/vacancies/${props.apiKey}/vacancy/`
        }
        ReturnToSearchURL={
          siteConfig.companyCareersSite
            ? `${siteConfig.careersSiteDomain}/vacancies/`
            : siteConfig.ReturnToSearchURL ||
              `${siteConfig.careersSiteDomain}/vacancies/${props.apiKey}/`
        }
        contentDataData={props.contentDataData || {}}
        location={siteConfig.showLocationJA}
        region={siteConfig.showRegionJA}
        category={siteConfig.showCategoryJA}
        industry={siteConfig.showIndustryJA}
        jobType={siteConfig.showJobTypeJA}
        jobTime={siteConfig.showJobTimeJA}
      />

      <section className="wrapper">
        <div className="vacancies-landing-cta">
          <div className="container">
            <div className="row clearfix">
              <div className="col-md-12 column">
                {' '}
                <div>
                  <div className="site-spacing">
                    <article aria-label="Subscribe for job alerts">
                      <div className="ctacard soft">
                        <div className="ctacard__body d-flex justify-content-center">
                          <div className="col-md-10 col-lg-11 text-center">
                            <h2 className="ctacard__body__title">
                              <span>Still looking for the right role?</span>
                            </h2>
                            <p className="ctacard__body__summary">
                              <span>
                                Subscribe for alerts straight to your inbox and
                                be the first to see our new vacancies.
                              </span>
                            </p>
                            <div className="btn__wrapper">
                              <Link
                                to="/job-alerts/"
                                className="btn btn--action"
                              >
                                Subscribe to job alerts
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>{' '}
                </div>
              </div>{' '}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
